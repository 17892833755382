import React, { useState } from "react";
import hero from "../images/design.png";
import Carousel from "react-multi-carousel";
import {
  corporate,
  sectorSKillCounsil,
  governmentInstitutional,
} from "../data/work-partners-data";
import { Link } from "gatsby";
import {
  projectPortals,
  education,
  skilling,
  consulting,
  heroSection,
  edTech,
  internationalPlacements,
} from "../data/hero-section-data";
import Team from "./team";
import australia from "../images/AustraliaFlyer_page.jpg";
import germany from "../images/GermanyFyer_page.jpeg";
// import AustraliaFlyer from "../pdfs/AustraliaFlyer.pdf";
// import AustraliaFlyer from "../pdfs/GermanyFyer.pdf";
import Nav from "react-bootstrap/Nav";
import Header from "../components/header";
import Footer from "../components/footer";

export default function Hero() {
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  const handleClick2 = () => {
    console.log("i am clicked");
    setShowModal2(true);
  };
  const responsiveWorkPartners = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const responsiveOthers = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <Header />
      <div className="custom-container px-2 px-sm-2 px-md-5 px-lg-5 px-xl-5">
        <div className="">
          <div className="row py-3">
            <div className="mt-5 pt-5 col-12 col-sm-12 col-md-12 col-lg-11 col-xl-6">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-8">
                  {/* <h1 className="hero-section-text-hero">
                    Skill Yatra Test
                    <br />
                    <div className="row">
                      <div className="col-md-4" style={{ marginTop: "5%" }}>
                        <Link
                          style={{
                            background: "#e77051",
                            color: "#fff",
                            fontWeight: "bolder",
                            fontSize: "18px",
                          }}
                          href="https://forms.gle/xG8zc1pg4zTuusQ86"
                          target="_blank"
                          className="p-3 rounded"
                        >
                          Assamese
                        </Link>
                      </div>
                      <div className="col-md-4" style={{ marginTop: "5%" }}>
                        <Link
                          style={{
                            background: "#e77051",
                            color: "#fff",
                            fontWeight: "bolder",
                            fontSize: "18px",
                          }}
                          className="p-3 rounded"
                          href="https://forms.gle/67TJYbtg8WCJojmt9"
                          target="_blank"
                        >
                          English
                        </Link>
                      </div>
                    </div>
                    <br />
                  </h1> */}
                  <h1 className="hero-section-text-hero">
                    <span className="common-color">Unifiers </span> has been set
                    up
                    <br />
                    with this Belief that <br />
                    <span className="common-color">People </span>and
                    <span className="common-color"> Ideas</span>
                    <br /> can make a
                    <span className="common-color"> Beautiful World</span>.
                  </h1>
                </div>
              </div>

              <h1
                className="hero-section-text"
                style={{ color: "#BEBEBE", paddingTop: "10%" }}
              >
                Our projects portals
              </h1>
              <ul className="brands_list_portal">
                {projectPortals.map((data) => {
                  return (
                    <>
                      <li className="brand_item_portal">
                        <h2 className="brand_logo_portal">
                          <a title="" href={data.link} target="_blank">
                            <img
                              className="brand_logo_portal"
                              src={data.url}
                              alt={``}
                            />
                          </a>
                        </h2>
                      </li>
                    </>
                  );
                })}
              </ul>
            </div>
            <div className="mt-5 pt-5 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
              <Carousel
                responsive={responsive}
                arrows={false}
                showDots={true}
                infinite={true}
                autoPlay={true}
              >
                {heroSection.map((data) => {
                  return (
                    <div>
                      <div className="">
                        <div className="">
                          <img src={data.url} className="img-fluid" />
                        </div>
                      </div>
                      <h6 className="mb-5 px-3 text-center caption-text">
                        {data.caption}
                      </h6>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </div>

        {/* ======================= Education ============================== */}
        <div className="pt-0 pt-sm-0 pt-md-0 pt-lg-5 pt-xl-5" id="education">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
              <h1 className="heading">Education</h1>

              <div className="common">
                Ensuring <span className="common-color">Children</span> not just
                go to school
                <br />
                but become
                <br />
                <span className="common-color">
                  Happy, Upright & Successful Citizens
                </span>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <Carousel
                responsive={responsiveOthers}
                arrows={false}
                showDots={true}
                infinite={false}
                autoPlay={true}
              >
                {education.map((data) => {
                  return (
                    <div>
                      <div className="card">
                        <div className="card-body">
                          <img src={data.url} className="img-fluid" />
                        </div>
                      </div>
                      <h6 className="mb-5 px-3 text-center caption-text">
                        {data.caption}
                      </h6>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </div>

        {/* ================================== SKILLING =================== */}

        <div
          className="mt-5 pt-0 pt-sm-0 pt-md-0 pt-lg-5 pt-xl-5"
          id="skilling"
        >
          <div className="row">
            <div className="mt-5 col-12 col-sm-12 col-md-12 col-lg-5 col-xl-6 order-last order-sm-last order-md-last order-lg-last order-xl-first">
              <Carousel
                responsive={responsiveOthers}
                arrows={false}
                showDots={true}
                infinite={false}
                autoPlay={true}
              >
                {skilling.map((data) => {
                  return (
                    <div>
                      <div className="card">
                        <div className="card-body">
                          <img src={data.url} className="img-fluid" />
                        </div>
                      </div>
                      <h6 className="mb-5 px-3 text-center caption-text">
                        {data.caption}
                      </h6>
                    </div>
                  );
                })}
              </Carousel>
            </div>
            <div className="mt-5 col-12 col-sm-12 col-md-12 col-lg-5 col-xl-6 order-first order-sm-first order-md-first order-lg-first order-xl-last">
              <h1 className="heading">Skilling</h1>

              <div className="common">
                Connecting <span className="common-color"> Youth</span> to
                existing & emerging
                <span className="common-color"> Jobs </span> and{" "}
                <span className="common-color">Self Employment </span>
                opportunities <br />
              </div>
            </div>
          </div>
        </div>

        {/* ================================== EDTECH =================== */}

        <div className="mt-5 pt-0 pt-sm-0 pt-md-0 pt-lg-5 pt-xl-5" id="edtech">
          <div className="row">
            <div className="mt-5 col-12 col-sm-12 col-md-12 col-lg-5 col-xl-6">
              <h1 className="heading">EdTech</h1>

              <div className="common">
                Building{" "}
                <span className="common-color">Educational Technologies </span>
                <br />
                for learners across the spectrum <br />
                <span className="common-color">
                  - Childeren, Youth, & Adults
                </span>
              </div>
            </div>
            <div className="mt-5 col-12 col-sm-12 col-md-12 col-lg-5 col-xl-6">
              <Carousel
                responsive={responsiveOthers}
                arrows={false}
                showDots={true}
                infinite={false}
                autoPlay={true}
              >
                {edTech.map((data) => {
                  return (
                    <div>
                      <div className="card">
                        <div className="card-body">
                          <img src={data.url} className="img-fluid" />
                        </div>
                      </div>
                      <h6 className="mb-5 px-3 text-center caption-text">
                        {data.caption}
                      </h6>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </div>

        {/* ================================== INTERNATIONAL PLACEMENTS =================== */}

        <div
          className="mt-5 pt-0 pt-sm-0 pt-md-0 pt-lg-5 pt-xl-5"
          id="internationalPlacements"
        >
          <div className="row">
            <div className="mt-5 col-12 col-sm-12 col-md-12 col-lg-5 col-xl-6">
              <Carousel
                responsive={responsiveOthers}
                arrows={false}
                showDots={true}
                infinite={true}
                autoPlay={true}
              >
                {internationalPlacements.map((data) => {
                  return (
                    <div>
                      <div className="card">
                        <div className="card-body">
                          <img src={data.url} className="img-fluid" />
                        </div>
                      </div>
                      <h6 className="mb-5 px-3 text-center caption-text">
                        {data.caption}
                      </h6>
                    </div>
                  );
                })}
              </Carousel>
            </div>
            <div className="mt-5 col-12 col-sm-12 col-md-12 col-lg-5 col-xl-6">
              <h1 className="heading">International Placements</h1>

              <div className="common">
                Connecting
                <span className="common-color"> Indian Youth</span>
                <br />
                to the
                <span className="common-color"> Global Job </span>
                opportunities
                <br />
                <div className="row">
                  <div className="col-3">Jobs For: </div>
                  <div className="col-6">
                    <button
                      className="btn btn-primary"
                      style={{ background: "#75a55c" }}
                      onClick={() => setShowModal(true)}
                    >
                      Nurses @ Germany
                    </button>
                    <br />
                    <button
                      className="btn btn-primary"
                      style={{ background: "#75a55c" }}
                      onClick={() => setShowModal2(true)}
                    >
                      Auto Denters & Painters @ Australia
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* =================== CONSULTING ================================ */}

        <div
          className="mt-5 pt-0 pt-sm-0 pt-md-0 pt-lg-5 pt-xl-5"
          id="consulting"
        >
          <div className="row">
            <div className="mt-5 col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 order-last order-sm-last order-md-last order-lg-last order-xl-first">
              <h1 className="heading">Consulting</h1>

              <div className="common">
                Working with Public Sector, Corporate and Social <br />
                Sector Partners to make{" "}
                <span className="common-color">Transformational</span>{" "}
                Education, <br />
                Skilling & Capacity Building{" "}
                <span className="common-color">Plans</span>
              </div>
            </div>
            <div className="mt-5 col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 order-first order-sm-first order-md-first order-lg-first order-xl-last">
              <Carousel
                responsive={responsiveOthers}
                arrows={false}
                showDots={true}
                infinite={false}
                autoPlay={true}
              >
                {consulting.map((data) => {
                  return (
                    <div>
                      <div className="card">
                        <div className="card-body">
                          <img src={data.url} className="img-fluid" />
                        </div>
                      </div>
                      <h6 className="mb-5 px-3 text-center caption-text">
                        {data.caption}
                      </h6>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </div>

        {/* =================== WORK PARTNERS ===================== */}
        {/* <div className="row">
          <div className="col-md-4">Government & Institutional</div>
          <div className="col-md-8">
            <Carousel
              responsive={responsiveWorkPartners}
              arrows={true}
              showDots={true}
              infinite={true}
              autoPlay={true}
            >
              {governmentInstitutional.map((data) => {
                return (
                  <div>
                    <div className="card">
                      <div className="card-body">
                        <img src={data.url} className="img-fluid" />
                      </div>
                    </div>
                    <p className="mb-5 text-center">{data.caption}</p>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div> */}
        <div className="pt-5 mt-5">
          <h1 className="heading text-center">
            Some of our <span className="common-color">work partners</span>
          </h1>
          <div className="mt-4">
            <h2 className="second-heading text-center">
              Government & Institutional
            </h2>

            <ul className="brands_list">
              {governmentInstitutional.map((data) => {
                return (
                  <li className="brand_item">
                    <h2 className="brand_logo">
                      <a title="" href="">
                        <img className="brand_logo" src={data.url} alt={``} />
                      </a>
                    </h2>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="row align-items-center mt-4">
            <div className="col-md-12">
              <h2 className="second-heading text-center">
                Sector Skill Councils
              </h2>
            </div>
            <div className="col-md-12">
              <ul className="brands_list">
                {sectorSKillCounsil.map((data) => {
                  return (
                    <li className="brand_item">
                      <h2 className="brand_logo">
                        <a title="" href="">
                          <img className="brand_logo" src={data.url} alt={``} />
                        </a>
                      </h2>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className="row align-items-center mt-4">
            <div className="col-md-12">
              <h2 className="second-heading text-center">Corporate</h2>
            </div>
            <div className="col-md-12">
              <ul className="brands_list">
                {corporate.map((data) => {
                  return (
                    <li className="brand_item">
                      <h2 className="brand_logo">
                        <a title="" href="">
                          <img className="brand_logo" src={data.url} alt={``} />
                        </a>
                      </h2>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>

        {/* team */}
        <Team />
      </div>

      {/* <!-- Modal --> */}
      <div
        className="modal"
        tabindex="-1"
        style={{ display: showModal ? "block" : "none" }}
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div
              class="modal-header text-white"
              style={{ background: "#75a55c" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowModal(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div>
                <a
                  target="_blank"
                  class="text-white"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSeQPzpxHrPXRIpfowO5lI-Z7MriaAKmrMO9850zOIYMKyCGVg/viewform"
                >
                  Register Now
                </a>
              </div>
            </div>
            <div class="modal-body">
              {" "}
              <img src={germany} className="img-fluid" width="80%" />
            </div>
            <div class="modal-footer">...</div>
          </div>
        </div>
      </div>

      <div
        className="modal"
        tabindex="-1"
        style={{ display: showModal2 ? "block" : "none" }}
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div
              class="modal-header text-white"
              style={{ background: "#75a55c" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowModal2(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div>
                {" "}
                <a
                  className="text-white"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSezoJ0FUN-IAcXIwa3G0T7KeqSu7tPXvjoLd7DlU_TV7wv4ag/formrestricted"
                  target="_blank"
                >
                  Register Now
                </a>
              </div>
            </div>
            <div class="modal-body">
              {" "}
              <img src={australia} className="img-fluid" width="80%" />
            </div>
            <div class="modal-footer">...</div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
